@import 'themes';

.fsTable {
    @include themify(true) {
        background-color: themed('accentBackgroundColor');
        color: themed('textColor');
    }

    width: 100%;
    background-color: #fff;

    &_header {
        display: flex;
        align-items: center;
        @include themify(true) {
            border-bottom: 1px solid themed('lineColor');
        }
        padding: 16px 8px;
    }

    &_line {
        display: flex;
        align-items: center;
        @include themify(true) {
            border-bottom: 1px solid themed('lineColor');
        }
        height: 45px;
        padding: 0 8px;
    }

    &_cell {
        display: flex;
        align-items: center;
        padding: 0 8px;

        &-head {
            padding: 0 8px;
            font-size: 13px;
            color: $fsDateColor;
            // overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
