@import './themes.scss';

html {
    &::-webkit-scrollbar {
        width: 6px;
    }

    &.theme-dark {
        &::-webkit-scrollbar-track {
            background-color: #262a2b;
        }
        &::-webkit-scrollbar-thumb {
            background-color: lighten(#2d2d2d, 12%);
        }
        &::-webkit-scrollbar {
            background-color: #262a2b;
        }
    }
    &.theme-light {
        &::-webkit-scrollbar-track {
            background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #e2e2e2;
        }
        &::-webkit-scrollbar {
            background-color: #fff;
        }
    }
}

.scrollbar {
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
        @include themify(true) {
            background-color: themed('backgroundColor');
        }
    }

    &::-webkit-scrollbar {
        width: 4px;

        @include themify(true) {
            background-color: themed('backgroundColor');
        }
    }

    &::-webkit-scrollbar-thumb {
        @include themify(true) {
            background-color: themed('activeBackgroundColor');
        }
    }
}
