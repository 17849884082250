$themes: (
    light: (
        backgroundColor: #fff,
        accentBackgroundColor: #fff,
        activeBackgroundColor: #e2e2e2,
        textColor: #9a9a9a,
        lineColor: #efefef,
        secondaryTextColor: #535353,
        primaryColor: #62bad8,
        secondaryColor: #51c2ac,
    ),
    dark: (
        backgroundColor: #262a2b,
        accentBackgroundColor: #222526,
        activeBackgroundColor: lighten(#2d2d2d, 12%),
        textColor: #dddd,
        lineColor: #383d3f,
        secondaryTextColor: #535353,
        primaryColor: #62bad8,
        secondaryColor: #51c2ac,
    ),
);

@mixin themify($globalComponent: false) {
    @each $theme, $map in $themes {
        @if ($globalComponent) {
            .theme-#{$theme} & {
                $theme-map: () !global;

                @each $key, $submap in $map {
                    $value: map-get(map-get($themes, $theme), '#{$key}');
                    $theme-map: map-merge(
                        $theme-map,
                        (
                            $key: $value,
                        )
                    ) !global;
                }

                @content;
                $theme-map: null !global;
            }
        } @else {
            :host-context(.theme-#{$theme}) & {
                $theme-map: () !global;

                @each $key, $submap in $map {
                    $value: map-get(map-get($themes, $theme), '#{$key}');
                    $theme-map: map-merge(
                        $theme-map,
                        (
                            $key: $value,
                        )
                    ) !global;
                }

                @content;
                $theme-map: null !global;
            }
        }
    }
}

@function themed($key) {
    @return map-get($theme-map, $key);
}
