@use '@angular/material' as mat;
@use 'themes.scss';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`

@include mat.core();
$primary: mat.m2-define-palette(mat.$m2-teal-palette);
$accent: mat.m2-define-palette(mat.$m2-indigo-palette);

$button-typography: mat.m2-define-typography-level(
    $font-family: Roboto,
    $font-weight: 400,
    $font-size: 1rem,
    $line-height: 1.1,
    $letter-spacing: normal,
);

$typography: mat.m2-define-typography-config(
    $font-family: "'Exo', sans-serif",
    $button: $button-typography,
);

// Define a light theme
$light-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $primary,
            accent: $accent,
        ),
    )
);
// Define a dark theme
$dark-theme: mat.m2-define-dark-theme(
    (
        color: (
            primary: $primary,
            accent: $accent,
        ),
        typography: $typography,
    )
);

@include mat.all-component-themes($dark-theme);

.theme-light {
    @include mat.all-component-colors($light-theme);
}

.mat-mdc-dialog-content {
    max-height: 80vh !important;
}

.mat-mdc-dialog-container,
mat-sidenav {
    @include themify(true) {
        background-color: themed('backgroundColor') !important;
        color: themed('textColor') !important;
    }
}

.mat-mdc-card,
.mat-mdc-table,
.mat-mdc-dialog-surface.mdc-dialog__surface {
    @include themify(true) {
        background-color: themed('accentBackgroundColor') !important;
    }
}

.cdk-drag,
.mat-tab-link,
mat-label,
mat-card-title,
.mat-mdc-menu-submenu-icon,
.mdc-list-item__primary-text,
.mdc-tab__text-label,
.mdc-form-field,
strong {
    @include themify(true) {
        color: themed('textColor') !important;
    }
}

.mat-mdc-menu-content {
    mat-icon {
        @include themify(true) {
            color: themed('textColor') !important;
        }
    }
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    @include themify(true) {
        background-color: themed('backgroundColor');
    }
}

.cdk-overlay-pane {
    &.dialog-full {
        width: 100% !important;
    }

    &.dialog-sm {
        @media (min-width: 768px) {
            width: 400px !important;
        }

        @media (max-width: 768px) {
            width: 100% !important;
        }
    }

    &.dialog-md {
        @media (min-width: 768px) {
            width: 600px !important;
        }

        @media (max-width: 768px) {
            width: 100% !important;
        }
    }

    &.dialog-lg {
        @media (min-width: 768px) {
            width: 800px !important;
        }

        @media (max-width: 768px) {
            width: 100% !important;
        }
    }

    &.dialog-xl {
        @media (min-width: 768px) {
            width: 90vw !important;
        }

        @media (max-width: 768px) {
            width: 100% !important;
        }
    }
}

.mat-mdc-card-header-text {
    width: 100%;
    .mat-mdc-card-title {
        text-align: center;
        font-size: 24px;
    }
}

mat-card-content {
    h5 {
        font-size: 1.15rem;
    }
}

.card {
    @include themify(true) {
        background-color: themed('accentBackgroundColor');
    }
}
