@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'styles/styles';

* {
    font-family: 'Exo', sans-serif;
}

html,
body {
    min-height: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;

    @include themify(true) {
        color: themed('textColor');
    }
}

main {
    @include themify(true) {
        background-color: themed('backgroundColor');
    }
}

h1,
h2,
h3,
h4,
h5 {
    @include themify(true) {
        color: themed('textColor');
    }
}

h1 {
    font-size: 36px;
    margin-bottom: 16px;
    font-weight: 500;
}

h3 {
    font-size: 24px;
    margin-bottom: 32px;
    font-weight: 500;
}

.fade.in {
    opacity: 1;
}

.modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-backdrop.in {
    opacity: 0.5;
}

.fsLoading {
    display: block;
    height: 100%;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.clickable {
    cursor: pointer;
}
.movable {
    cursor: move;
}

.text-primary {
    @include themify(true) {
        color: themed('primaryColor') !important;
    }
}
.text-secondary {
    @include themify(true) {
        color: themed('secondaryColor') !important;
    }
}
