@import './colors';

.text-themed {
    @include themify(true) {
        color: themed('textColor');
    }
}

body {
    min-height: 100vh;
}

mat-icon.clickable {
    cursor: pointer;
    &:hover {
        color: $colorBlue !important;
    }
}
