@import 'themes';

.grid {
    grid-template-rows: auto;
    display: grid;
    font-size: 14px;

    @include themify(true) {
        background-color: themed('accentBackgroundColor');
        color: themed('textColor') !important;
    }

    .grid-row {
        display: contents;

        &:not(:last-child) {
            > * {
                @include themify(true) {
                    border-bottom: 1px solid themed('lineColor');
                }
            }
        }

        > * {
            padding: 1rem;
            display: flex;
            align-items: center;
            padding: 0px 8px;
            min-height: 36px;
        }
    }
}
