@import './themes.scss';
ngx-charts-chart {
    .ngx-charts {
        text {
            @include themify(true) {
                fill: themed('textColor');
            }
        }
    }

    .chart-legend {
        .legend-label-text {
            @include themify(true) {
                color: themed('textColor');
            }
        }
    }
}
