$fsTitle: #aac8dc;
$fsMenuLinkColor: #bcbcbc;

$fsDateColor: #9a9a9a;
$fsScoreLight: #b1b1b1;
$fsScoreDark: #535353;
$fsScoreBorder: #e2e2e2;
$fsNamesColor: #888888;

$fsGreen: #56cfb7;
$fsBtnGreen: #5ed7a2;
$darkGreen: #469a6d;

$colorGreen: #51c2ac;
$colorBlue: #62bad8;

$colors: (
    'disabled': #535353,
    'title': #aac8dc,
);
