.resetBtn {
    border: 0;
    background-color: transparent;
    outline: none;

    &:focus {
        outline: none;
    }
}

@mixin fsButton($col: $fsBtnGreen, $fs: 18px, $padd: 16px 32px) {
    display: inline-block;
    border: 0;
    outline: 0;
    font-size: $fs;
    font-weight: 500;
    color: #fff;
    padding: $padd;
    background-color: $col;
    transition: background-color 0.2s;
    cursor: pointer;
    user-select: none;

    &:hover,
    &:focus {
        transition: background-color 0.3s;
        background-color: darken($col, 10%);
    }
}

.fsFAB {
    &_add {
        @extend .resetBtn;
        display: flex;
        align-items: center;
        border-radius: 26px;
        max-width: 52px;
        overflow: hidden;
        background-color: $fsGreen;
        text-align: center;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
        user-select: none;
        transition: box-shadow 0.25s;
        padding: 12px;
        cursor: pointer;
        transition: max-width 0.25s;

        i {
            display: block;
            color: #fff;
            font-size: 28px;
        }

        span {
            line-height: 1;
            font-size: 20px;
            font-weight: 500;
            color: #fff;
            padding-left: 12px;
            white-space: nowrap;
            line-height: 1;
        }

        &:hover,
        &:focus {
            span {
                will-change: auto;
                padding-left: 8px;
                transition: padding-left 0.2s;
            }

            max-width: 200px;
            will-change: auto;
            padding-right: 16px;
            transition:
                box-shadow 0.35s,
                max-width 0.4s ease;
            box-shadow: 0 0 16px rgba(0, 0, 0, 0.35);
        }

        &.sm {
            display: inline-flex;
            padding: 8px;
            border-radius: 19px;
            height: 38px;
            max-width: 38px;

            i {
                font-size: 22px;
            }

            span {
                padding-left: 8px;
                font-size: 16px;
                padding-right: 12px;
            }

            &:hover,
            &:focus {
                padding-left: 8px;
                max-width: 150px;
            }
        }
    }
}

.fsButton {
    &_save {
        @include fsButton();
    }
}

button:focus {
    outline: none !important;
}
